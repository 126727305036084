.background_color_image
{
    background-image: url(../../Assests/contactimages/back2.png);
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 105% 100%;
    padding-bottom: 8%;
    padding-top: 5%;
}

.background_over_flow
{
    overflow-x: hidden;
}

.line_height
{
    line-height: 28px;
    color: gray !important;
    font-weight: 400 !important;
    padding-bottom: 20%;
    // text-align: justify;
    
}



.button_scroll_down
{
    background-color: rgb(217, 189, 62);
    padding-left: 4%;
    padding-right: 2%;
    padding-top: 3%;
    padding-bottom: 3%;
    border-radius: 50px;
    color: white;
}

.border_down_button
{
    border: 1.5px solid white;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 50px;
}

.color_text_sub_gray
{
    color: gray;
}

.margin_negative_top
{
    margin-top: -40px;
}

.color_pop
{
    color: #1E185F;
}

.card_border
{
    border: 1px solid lightgray;
    border-radius: 15px;
    background-color: rgb(241, 246, 250);
    height: 100vh;
}

.color_gray
{
    color: gray;
}

.hover__linka
{
   padding-top: 1%;
}

.background_color_button_warning
{
    color: rgb(219, 193, 74);
    // padding-left: 4%;
    padding-right: 4%;
    font-weight: 900 !important;
    // padding-top: 4%;
    padding-bottom: 4%;
    border-radius: 30px;
    cursor: pointer;
}

.height_og_div
{
    height: 70vh;
    text-align: center;
}

.img_width_loader
{
    width: 10%;
    padding-top: 10%;
}

.width_profiles
{
    width: 70%;
}

.text_same
{
    color: #1E185F;
}

.padding_handle
{
    padding-left: 10%;
}

.img_size_fix
{
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.display_mobile
    {
        display: none;
    }

@media only screen and (max-width: 881px) {
    .display_mobile
    {
        display: block;
    }
    .display_pc
    {
        display: none;
    }
    .img_spacing_mob {
     
     padding-bottom: 9%;
    }
    .card_border
{
    border: 1px solid lightgray;
    border-radius: 15px;
    background-color: rgb(241, 246, 250);
    height: auto;
}
.padding_handle
{
    padding-left: 6%;
    padding-right: 6%;
    font-size: auto !important;
}
.line_height
{
    line-height: 21px;
    color: gray !important;
    font-weight: 400 !important;
    padding-bottom: 5%;
    font-size: 90%;
    
}
.line_height1
{
    line-height: 21px;
    color: gray !important;
    font-weight: 400 !important;
    padding-bottom: 20%;
    // text-align: justify;
    font-size: 90%;
    padding-right: 8%;
    
}


  }


  @media (max-width:1400px) and (min-width:1300px) {
    .card_border
    {
        border: 1px solid lightgray;
        border-radius: 15px;
        background-color: #F1F6FA;
        height: 65vh;
    }
    
  }

  @media (max-width:1600px) and (min-width:1400px) {
    .card_border
    {
        border: 1px solid lightgray;
        border-radius: 15px;
        background-color: #F1F6FA;
        height: 80vh;
    }
    
  }

  @media (max-width:1900px) and (min-width:1600px) {
    .card_border
    {
        border: 1px solid lightgray;
        border-radius: 15px;
        background-color: #F1F6FA;
        height: 70vh;
    }
    
  }

  @media (max-width:2500px) and (min-width:1900px) {
    .card_border
    {
        border: 1px solid lightgray;
        border-radius: 15px;
        background-color: #F1F6FA;
        height: 60vh;
    }
    
  }

  @media (max-width:3000px) and (min-width:2500px) {
    .card_border
    {
        border: 1px solid lightgray;
        border-radius: 15px;
        background-color: #F1F6FA;
        height: 45vh;
    }
    
  }