
.testimonials_ {
    padding: 1.25rem;
    margin: 17PX 6.25rem;
}

  .testimonials__title {
    display: flex;
      flex-direction: column;
      /* width: 40%; */
      height: 220px;
      align-items: flex-end;
      justify-content: center;
      padding: 0rem 3rem 0rem 3rem;
      border-right: 1px solid #dcdcdc;
      margin-right: 15px !important;
  }
  
  .testimonials_ h2 {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: #110b57 !important;
  }
  
  .testimonials_ p {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
    color: #7d7d7d !important;
    padding: 0px 12px 25px 25px;
  }
  
  .testimonials_ p svg {
     margin-bottom: 10px;
  }
  .testimonials_ span {
    /* font-weight: 300; */
    font-size: 20px;
    line-height: 28px;
    /* padding-top: 20px; */
    text-align: left;
    color: #2e2e2e;
    padding: 0px 15px 1px 0px;
    margin: 2px 0px 0px 15px;
  }
  
  .testimonials_ section {
    /* width: 80%; */
    display: flex;
    justify-content: space-around;
  }
  
  .testimonials_ a {
    padding: 2px 6px;
    width: 30px;
    /* height: 30px; */
    height: 30px;
    border: 1.5px solid #1f105a;
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .clientName {
    margin: 0px 10px 0px 50px;
    
  }
  .carousel .control-dots {
    display: none !important;
  }

  .reverse__flex {
    display: flex;
    flex-direction: row-reverse;
    /* flex flex-col md:flex-row-reverse */
  }

  .sec__width {
    width: 50%;
    /* w-full md:w-1/2 */
  }
  .arrows__sec {
    display: flex;
    justify-content: start;
    margin-top: 5px;
    /* flex justify-center md:justify-start mt-1 */
  }

  .carousel_container{
    margin-bottom: 0;
    overflow: hidden;
  }
  
  // @media (max-width: 768px)
  @media only screen and (min-width: 900px) {
    .arrows__sec{
      padding-left: 20px;
    }
  }
  @media only screen and (max-width: 900px) {
    .testimonials_ {
      padding: 0px !important;
      margin: 0px 0px 0px 0px !important;
      /* width: 100% !important; */
      align-items: center;
    }
    .carousel_container{
      margin-bottom: 30vh;
      overflow: visible;
    }
    .testimonials_ {
        padding: 1.25rem;
        margin: 0PX  !important;
    }
  .reverse__flex {
    display: flex;
    flex-direction: column;
  }
  .sec__width {
    width: 100% !important;
    /* w-full md:w-1/2 */
  }

  .arrows__sec {
    /* display: flex; */
    justify-content: center !important;
     /* flex justify-center md:justify-start mt-1 */
  }
    .testimonials_ section {
      display: flex !important;
      flex-direction: column !important;
    }
  
    .testimonials__title {
      display: flex;
      /* flex-direction: column; */
      /* width: 40%; */
      height: 40px;
      align-items: center;
      justify-content: center;
      padding: 2rem 1rem;
      border-right: none !important;
      /* margin-right: 15px !important; */
    }

    .carousel .slide iframe {
        display: inline-block !important;
        width: 90% !important;
        margin: 0px 10px !important;
        border: 0 !important;
    }
    .testimonials_ p {
        font-family: "Roboto" !important;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        text-align: left;
        color: #7d7d7d !important;
        padding: 15px 12px 5px 25px;
      }
     
  }
  