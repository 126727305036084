@mixin smallDesktop {
  @media (max-width: "1000px") {
    @content;
  }
}

@mixin tablet {
  @media (max-width: "799px") {
    @content;
  }
}

@mixin mobile {
  @media (max-width: "500px") {
    @content;
  }
}

@mixin  onlyDesktop {
  @media (min-width:"1000px") {
    @content
  }
}