.main-wrapper {
    overflow-y: auto;
    min-height: 70vh;
    max-height: 70vh;
    width: 100%;
    overflow-y: scroll;
}

.img-wrapper {
    // height: 70vh;
    height: 100%;
    width: 100%;
}

.img-wrapper img{
    width: 100%;
    height: 100%;
    // object-fit: cover;
}