@import "../../App.scss";

.fae--booking-detail-page-main-container {
  @include flex(center, center);
  flex-direction: column;
  gap: 20px;
}
 .fae--consent-form{
   width: 70%;
   margin: auto;
 }
 .add-consent-btn{
   margin: auto;
   display: block;
   margin-top: 14px;
   padding: 11px 20px;
 }
 .fae--consent-label {
  padding: 20px 6px !important;
  width: 99%;
  display: block;
}
.fae--consent-img-container img {
  width: 100px;
  height: 38px;
  margin-left: 5px;
  border-radius: 3px;
  object-fit: cover;
}
.fae--addresses-delete-stroke {
  width: 15px;
  height: 3px;
  background-color: white;
  border-radius: 1px;
}
 

.fae--addresses-delete-stroke {
  background-color: #ffffff;
  border-radius: 5px;
  color: #ff1818;
  position: relative;
  font-size: 5px;
  padding: 6px 0px;
  top: -38px;
  float: right;
  text-align: center;
  margin: 1px;
  border: 2px solid #d2425b;
}
.fae--consent-img-container {
  display: flex;
  justify-content: end; 
  width: 97%;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  // background-color: white;
  padding-top: 12px;
  padding-right: 4px;
}
.fae-question-field{
  margin-top: 10px;
}
.fae-consent-se {
  padding: 1px 27px;
  width: 95%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #f2f2f2; 
}
.fae--consent-form .fae--text {
  font-size: 12px;
}
.fae--consent-form .fae--text-field-container { 
  grid-gap: 5px;
  gap: 0px;
  padding: 5px; 
}
.fae-consent-add-video {
  width: 97px;
  border: dashed;
  padding: 5px;
}
.fae--addresses-delete-stroke span {
  font-size: 17px;
  font-weight: bold;
  color: black;
  position: relative;
  top: -10px;
}
.fae-consent-dob-field .fae--date-picker-container {
  padding: 4px;
}
.fae-question-field {
  margin-top: 21px;
  background-color: white;
  border-radius: 4px;
}
.fae--consent-form .fae--shadow-box { 
  box-shadow: initial;
}
.fae-http-data-section {
  border-radius: 17rem !important;
  margin-top: -17px;
  padding: 12px 2px;
}
.fae-tap-sign-container {
  padding: 58px 67px;
  border: 1px #c55656 dashed;
  margin: auto;
  display: block;
  width: 60%;
}
.fae-tap-sign-container button {
  padding: 12px 14px;
  font-size: 18px;
  font-weight: 500;
  color: #c55656;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c55656;
  display: block;
  margin: auto;
}

.fae-signature-container { 
  top: 10%;
  left: 10%;
}

.fae-signature-sigContainer {
  width: initial;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  position: relative;
  display: block;
}
 
.fae-signature-sigPad {
  width: 210px;
  background-color: white;
  height: 122px;
  border-bottom: 1px solid #878585;
  margin: auto;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: url("http://icons.iconarchive.com/icons/designcontest/vintage/32/Patent-Pen-icon.png") 0 20, progress;
}
.fae-sig-btns button{
  margin-right: auto;
  right:26px;
  position: relative;
}
.fae-signature-buttons {
  width: 30px;
  height: 30px;
  border: initial;
  color: rgb(0, 0, 0);
  border-radius: 50px; 
  margin-right: 10px !important;
  background-color: #ffffff;
  padding-top: 3px;
  box-shadow: 0 1px 20px #959494;
}
.fae-signature-remove{
  position: relative;
  width: 30px;
  height: 30px;
  border: initial;
  color: rgb(0, 0, 0);
  border-radius: 50px; 
  margin-right: 10px !important;
  background-color: #ffffff;
  padding-top: 3px;
  box-shadow: 0 1px 20px #959494;
  position: absolute;
  right: 69px;
  top: 18px;
}
.fae-sig-btns { 
  text-align: right;
}
.fae-draw-sign-text{
  text-align: center;
  padding-top: 5px;
  font-weight: 500;
}
.fae--consent-form .shadow-box-padding {
  padding: initial;
}
.fae-consent-lable { 
  background-color: #f7f8ff;
  padding-bottom: 10px;
}
.fae-body-consent-form #popup-root .popup-content {
    height: 192px !important;
}
.fae-consent-message-lable{
  text-align: center;
  padding: 5rem;
  font-weight: bold;
  text-transform: capitalize;
}
.fae-signature-sigImage {
  background-size: 200px 50px;
  background-color: white;
  margin: auto;
  display: block;
  width: 160px;
  border: 1px rgb(155, 155, 155) dashed;
  padding: 7px;
  height: 137px;
}
.fae--consent-form .fae-user-data .shadow-box-padding { 
  border: 1px solid #d9d9d9;
}

.fae-user-data {
  display: block;
  padding: 10px 31px 32px;
  background-color: #f7f8ff;
}
.fae-user-data .DatePicker__input {
  padding: 0.4em 0em;
  outline: initial;
}
// signature popups 
  .popup-content {
    width: 29% !important; 
    border-radius: 10px;
}
.fae-signature-sigContainer .close {
  float: right;
  top: 0;
  position: absolute;
  right: 7px;
  font-size: 31px;
  cursor: pointer;
}
.fae-signature-sigContainer .popup-arrow { display: none;}
[data-popup=tooltip].popup-overlay {
  background: #46333338;
}
.fae-signature-sigContainer  .popup-sure-btn{
   background-color: red;
   color: white;
   border-radius: 4px;
   text-align: right;
   padding: 5px;
   width: 30px;
   margin-left: auto;
   float: right;
   cursor: pointer;
   margin-top: 17px; 
 }
 .fae-signature-sigContainer .popup-content-con{
    display: grid;
 } 
 #fae-gender-field .fae--main-container{
  background-color: #f7f8ff;
 }
 .fae--consent-form .fae--text { 
  color: #848484;
}
.fae--consent-form .PrivateRadioButtonIcon-root-14 {
  display: flex;
  position: relative;
  background-color: #f8f8f8 !important;
  border: 1px solid #d4d4d4 !important;
  border-radius: 4px;
  color: #e91e63 !important;
} 
.MuiIconButton-label { 
  background-color: #d3d3d342;
  border: 1px solid #c5565661;
  border-radius: 3px;
}
@include mobile {
  .fae-user-data {
    padding: 10px 16px 32px;
}
  .fae-signature-sigContainer {
    width: initial;
    margin: 0 auto;
    background-color: #fff;
    padding: 2px;
    position: relative;
}
  .fae-consent-add-video {
    width: 30px; 
    border: dashed;
    padding: 5px;
  }
  .fae-consent-add-video .sub-heading{
      font-size: 10px;
  }
  .fae--consent-form {
    width: 88%; 
  }
  .fae-http-data-section {
    margin-left: 0px;
    margin-right: 0px;
    // box-shadow: 0px 27px black;
}
 .fae-tap-sign-container {
    margin-top: 40px;
    padding: 58px 64px;
    border: 1px #c55656 dashed;

  } 
  .fae-tap-sign-container button{
    padding: 10px 14px !important;
    font-size: 18px;
    font-weight: 500;
    color: #c55656;
    border: 1px solid #c55656;
    margin: auto;
    display: block;

  }
  .fae--consent-form {
    width: 98%; 
 }
 .fae--consent-form .fae--text {
    font-weight: 600; 
  }
 .popup-content {
  width: 80% !important; 
 }
}
@include onlyDesktop{
  .fae-signature-sigImage { 
    width: 300px;  
    height: initial;
  }
}

