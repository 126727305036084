.img_style_event
{
    border-radius: 10px;
    border: 3px solid rgb(30, 24, 95);
    box-shadow: 1px 1px 5px 5px rgba(211, 211, 211, 0.64);
}

.img_aesthetic_clinic
{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    color: rgb(30, 24, 95);
    line-height: 60px;
}

.color_new_icon
{
    color: rgb(30, 24, 95) !important;
}

.text_color_event
{
    color: gray;
}