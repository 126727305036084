/* #########################################Header############################################### */
.dropbtn {
  background-color: #04aa6d00;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
}

.background_div {
  transition: 0.9s;

}

.background_div:hover {
  background-color: #d9bd3e !important;
  color: black;

}

.background_div .hover__heading:hover {
  color: #1E185F !important;
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 10000;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  /* margin-top: 5%; */
  border-top: 4px solid #1E185F;
  padding-bottom: 5% !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-right: 2px solid #d9bd3e;
  border-bottom: 2px solid #d9bd3e;
  border-left: 2px solid #d9bd3e;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;

}

.dropdown-content a {
  color: black;
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #dddddd00;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e4100;
}

.button_style_link {
  background-color: #DBC14A;
  color: white;
}


.mobile__navbar {
  display: none;
}

.video-js .vjs-big-play-button {
  right: 0;
  left: 0;
  width: 4rem;
  bottom: 0;
  height: 3rem;
  top: 10px;
  margin: auto;
  position: absolute;
}

.my-video-dimensions {
  width: 640px;
  height: 570px;
}

.hover__colomn li:nth-child(5) {
  padding-bottom: 16px;
}

.hover__colomn li:nth-child(9) {
  padding-bottom: 16px;
}

.testimonial-slide-video-dimensions {
  /* width: 100%;
    height: 347px; */
}

/* .testimonial-slide-video-dimensions   .vjs-control {
   top: -15px;
} */
#slide-video {
  width: 100%;
  height: 100%;
}

.phone__no {
  margin-bottom: 0px !important;
}

.icons__all {
  /* margin-top: 31%;
    margin-left: 29%; */
}

.short_tab_line_button {
  display: none;
}

.short_tab_ul {
  display: none;
}

.courses__text1:hover+.hover__div {
  border-top: none;
}

.sub__container {
  margin-top: -7px;
}

.hover__div {
  border-radius: 0.5rem;
  /* display: none; */
  position: absolute;
  top: 59px;
  width: 40%;
  right: 0;
  border: 1px solid;
  left: -15px px;
  background: white;
  /* padding: 10px 0px 15px 10px; */
  border-right: 3px solid #d9bd3e;
  z-index: 2;
}

.hover__container {
  padding-bottom: 12px;
}

.hover__container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
}

.hover__row {
  width: 100%;
  justify-content: space-between;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-right: -15px; */
  /* margin-left: -15px; */
  box-sizing: border-box;
}

.courses__text {
  cursor: pointer;
  background: initial;
  color: white;
  border: initial;
  outline: initial;
}

.hover__colomn img {
  width: 100%;
  margin-top: 52px;
}

.hover__colomn {
  justify-content: flex-start;
  padding-left: 2%;
  list-style: none;
}

.hover__item {
  margin: 5px 2px;
  display: list-item;
  text-align: -webkit-match-parent;
  border-bottom: 1px solid #ededed6b;

}

/* .hover__colomn:nth-child(4) {
    width: 14%; 
  } */
.hover__colomn:nth-child(3) {
  width: 30%;
}

.hover__colomn:nth-child(1) {
  width: 30%;
}

.boxV__top img {
  width: 54px;
  height: 65px;
}

.hover__colomn:nth-child(2) {
  width: 32%;
}

.hover__heading {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px !important;
  line-height: 20px;
  color: #110B57 !important;
  margin: 5px;
  padding-top: 10px;
  transition: 0.9s;
}

.hover__linka {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 14px !important;
  letter-spacing: 0.0em !important;
  text-transform: capitalize;
  color: #1e185f !important;
  background-color: transparent !important;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
  box-sizing: border-box;
  cursor: pointer;
}

.header__main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 6.3rem;
}

.short_tab {
  display: none;
}

.sub__headers {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
  margin-bottom: 0px;
}

.portal__button {
  height: 28.8px;
  width: 109.3px;
  border-radius: 14.4px;
  color: #1e185f;
  background-color: transparent;
  border: 1px solid #1e185f;
}

.sub__header2 {
  height: 59px;
  width: 100%;
  border-radius: 13.4703px 0px 0px 0px;
  background-color: #1e185f;
  color: white;
  PADDING: 1px 1px;
}

.navbar {
  display: flex;
  justify-content: flex-start !important;
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e);
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.phone__logo__No {
  display: flex;
  float: left;
  align-items: center;
  margin-bottom: 0px !important;
  margin-left: auto;
}

.header-secion {
  top: 40px;
}

a.courses__text,
button.courses__text {
  cursor: pointer;
  margin-top: 0%;
  text-decoration: none;
  color: white;
  padding: 6px 34px;
}

.coursesContainer .course-card {
  background-color: #3e425c82;
}

a.courses__text1 {
  margin-top: 1%;
  height: 28.8px;
  width: 109.3px;
  border-radius: 14.4px;
  background-color: #ffffff00;
  padding-left: 2%;
  margin-left: -15%;
  text-decoration: none;
  color: white;
}

.header__logo {
  margin: 10px;
}

a.courses__text:hover,
button.courses__text:hover {
  color: white;
  background: #d9bd3e;
  border-radius: 2rem;
}

.phone__logo {
  margin: 15px;
}

/* ##################PicContainer######################################################### */
.main__Image__Container {
  /* background-image: url("../components/images/mainpic.png"); */
  /* width: 1366px;
    height: 379px;
    background-repeat: no-repeat; */
  /* margin-top: -1%; */
  box-sizing: border-box;
}

.send__button__div {
  display: flex;
  justify-content: right;
}

.heading__1 {
  width: 114px;
  height: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 19.2px;
  font-family: "Roboto";
  font-style: normal;
}

.heading__2 {
  width: 383px;
  height: 20px;
  font-weight: 500;
  font-size: 30px;
  line-height: 19.2px;
  letter-spacing: 2.5%;
  color: #d9bd3e;
  font-family: "Roboto";
  font-style: normal;
}

.form__container {
  position: absolute;
  margin-top: -350px;
  z-index: 1;
  float: right;
  right: 7%;
  width: 26%;
  padding: 11PX;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  border: 1px solid #D9BD3E;
}

.height_w_of_image {
  height: auto !important;
}

@media screen and (min-width: 700px) {
  @media screen and (max-width: 881px) {
    .form__container {
      top: 110px !important;
      width: 45% !important;
    }
  }
}

@media screen and (min-width: 320px) {
  @media screen and (max-width: 699px) {
    .form__container {
      top: 9% !important;
      width: 45% !important;
    }
  }
}

.send__msg__div {
  /* display: flex;
    justify-content: flex-start;
    float: right; */
  margin-top: 2%;
}

.msg__text {
  color: #1f105a;
  font-size: 16px;
  margin-right: 20px;
  width: -1%;
  margin-top: 9px;
}

.send__msg__btn {
  color: #1e185f;
  font-family: "Roboto";
  background-color: #d9bd3e;
  font-size: 28px;
  line-height: 27px;
  border-radius: 100%;
  border: #d9bd3e;
  /* width: 46%; */
  padding: 7px 8px;
  font-weight: bold;
}

.form__heading {
  width: 100%;
  height: auto;
  text-align: center;
  padding-top: 10px;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 19px;
  line-height: 19px;
  color: #1e185f !important;
  background-color: white !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0px;
}

.form__elements {
  width: 100%;
}

.inputDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.form__inputs {
  width: 100%;
  height: 42.77px;
  font-family: "Roboto";
  font-size: 12px !important;
  background: #edf6fe;
  padding: 10px 0px 7px 10px;
  margin: 2px 0px;
  border: none;
  color: rgba(82, 82, 82, 0.65) !important;
}

.sub__container__main {
  width: 100%;
  height: 49px;
  background-color: #d9bd3e;
}

.cetagoryContainer__ro .primary {
  background: #1e185f;
  border-radius: 0px 0px 6px 6px;
}

.cetagoryContainer__ro .fae--service-card-text-mobile {
  color: white;
  font-weight: bold;
  height: 4rem;
  width: 100%;
  text-align: center;
  margin: auto;
}

.sub__container__main__text {
  margin-left: 2%;
  width: 108px;
  height: 69px;
  margin-top: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  position: relative;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  color: #1e185f;
  top: 5px;
}

.sub__container__main__text1 {
  margin-left: 1.5%;
  border-left: 3px solid white;
  /* width: 215px;
    height: 48px; */
  /* left: calc(50% - 215px / 2 - 372.5px);
    top: 495px; */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  position: relative;
  top: 3px;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  color: #1e185f;
  padding-left: 9px;
}

.sub__container2 {
  display: flex;
  background-color: transparent;
  width: 100%;
  justify-content: space-around;
  padding-top: 40px;
  padding-bottom: 50px;
  align-items: center;
}

.boxVContainer {
  width: 30%;
}

.boxV__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.boxV__top__h2 {
  font-family: "Roboto" !important;
  padding-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.075em;
  color: rgba(0, 0, 0, 0.7);
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 8px;
  background-color: #1e185f;
}

.information__text {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff !important;
  /* padding-bottom: 4%; */
  margin-bottom: 0px;
}

/* cetagorycontainer CSS ######################################################################## */
.CetagoryContainer__main {
  width: 95%;
  display: flex;
  justify-content: center;
  /* margin-top: 2%; */
  padding-top: 0 !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.cetagoryContainer__row {
  width: 95%;
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.contentContainer {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.headContenter__text {
  font-family: "Roboto" !important;
  margin-top: 2%;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 19px;
  text-align: center;
  color: #1E185F;
}

.fae--vertical-scroll-services {
  grid-row-gap: 0px;
}

.fae--service-card-text-mobile {
  padding: 9px;
  margin-bottom: 4px;
}

.headContenter__text2 {
  font-family: "Roboto" !important;
  width: 100%;
  margin: auto;
  padding-bottom: 42px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #1E185F;
  opacity: 0.6;
  padding-top: 15px;
}

.home_Feature {
  margin-top: -1%;
  width: 100%;
}

.home_Feature2 {
  margin-top: -11%;
  width: 100%;
}

.custom__carosal__row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.all__row {
  padding-top: 65px;
  justify-content: space-between;
  /* flex-wrap: nowrap; */
  width: 100%;
  display: flex;
  padding-bottom: 3rem !important;
}

.row__col {
  margin-bottom: 3rem !important;
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  box-sizing: border-box;
}

.row__images {
  width: -webkit-fill-available;
  height: auto !important;
  border-radius: 2%;
}

.content_box_feature {
  background: #1f105a;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
  height: 55px;
  align-items: center;
  margin-top: -2%;
}

.feature_title {
  font-size: 14px;
}

.feature_price {
  background: #d9bd3e;
  padding: 18px 3px;
  border-radius: 50%;
  margin-top: -37px;
  color: white;
  font-size: 20px;
  text-align: center;
  height: 64px;
  width: 65px;
}

.main__container {
  display: flex;
  justify-content: center;
  margin-top: 65px;
  margin-bottom: 65px;
  background: #eff7fa;
  padding: 60px 20px;
  margin-top: -4%;
}

.sub__main__row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headerContainer__heading {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #110b57 !important;
  margin-top: -3%;
}

.headerContainer__text {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #1E185F !important;
  padding: 1px 170px 6px 170px;
  opacity: 0.6;
}

.popular__Course {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Feature_vid_Div {
  width: 70%;
  height: auto;
  margin-bottom: 60px;
  margin-left: 14%;
}

iframe#feature_video {
  /* margin-left: 20%; */
}

.courses__videos {
  justify-content: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  overflow-x: scroll;
}

.slider_wrapper {
  display: flex;
  transition: transform 0.6s ease;
}

.slider {
  width: 100%;
  position: relative;
  /* overflow: hidden; */
}

.slider__wrapper {
  display: flex;
  transition: transform 0.6s ease;
}

.slider__item {
  flex: 0 0 20%;
  max-width: 25%;
}

.slider__video {
  cursor: pointer;
  height: 114px;
}

.contentContainer {
  overflow: hidden;
  height: auto;
  padding: 5px 15px;
}

.contentContainer__text {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #0c083b !important;
  padding-bottom: 10px;
  margin: 2% 0%;
}

.contentContainer a {
  margin-right: auto;
  text-decoration: none;
}

.vedio__container__a {
  text-decoration: none !important;
}

.discription {
  font-size: 12px;
  line-height: 26px;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 300;
  color: #353535 !important;
  margin: -2%;
}

/* ############################About##################################################### */
.home__section__3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -5%;
  padding-right: 1px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  width: 96%;
}

.home__section__3__row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.section3__row__h2 {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #110b57 !important;
  padding-top: 1%;
}

.section__row__text {
  justify-content: center;
  width: 100%;
  padding: 5px 248px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #1E185F;

  opacity: 0.6;
}

/* ????????????????????? */
.main_div2 {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  height: 350px;
}

.main_div__animation__2 {
  width: 800px;
  height: 275px;
  display: block;
  opacity: 1;
  box-sizing: border-box;
}

.animation__box__2 {
  display: flex;
  box-sizing: border-box;
  background-color: #d9bd3e;
  height: 100%;
  box-shadow: 0px 9px 4px rgb(0 0 0 / 25%);
}

.content_box_animation2 {
  background: #1e185f;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px;
  width: 85%;
  box-sizing: border-box;
}

.image_part2 {
  width: 40%;
  box-sizing: border-box;
  vertical-align: middle;
  border-style: none;
}

.aboutPic2 {
  width: 59% !important;
  object-fit: contain;
  display: block;
}

.text__part__2 {
  width: 55%;
  color: #ffffff !important;
}

.text__part__heading__home__section2 {
  color: white !important;
  font-weight: 600;
  font-size: 20px;
  margin: 5%;
}

.text__part__text__home__section2 {
  color: white;
  font-weight: 400;
  margin: 5%;
  font-size: 16px;
}

.animation__button2 {
  background-color: white;
  width: 45px;
  height: 45px;
  padding: 6px 10px;
  border-radius: 50%;
  border: none;
  margin-top: 14%;
  margin-left: 10%;
  margin-right: 3%;
}

.animation__button__span2 {
  color: #d9bd3e;
  font-size: 20px;
}

.flip__card {
  background-color: transparent;
  width: 68%;
  height: 100%;
  perspective: 1000px;
  margin-left: 22%;
  position: relative;
  height: 300px;
  margin-top: 2%;
}

.flip__card__inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip__card:hover .flip__card__inner {
  transform: rotateY(180deg);
}

.front__end,
.back__end {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.back__end {
  transform: rotateY(180deg);
}

/* ?????????????????????/ */
.main_div {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  height: 350px;
}

.main_div__animation__1 {
  width: 800px;
  height: 275px;
  display: block;
  opacity: 1;
  box-sizing: border-box;
}

.animation__box__1 {
  display: flex;
  box-sizing: border-box;
  background-color: #1e185f;
  height: 100%;
  box-shadow: 0px 9px 4px rgb(0 0 0 / 25%);
}

.animation__button {
  background-color: white;
  width: 45px;
  height: 45px;
  padding: 6px 10px;
  border-radius: 50%;
  border: none;
  margin-top: 14%;
  margin-left: 5%;
}

.animation__button__span {
  color: #d9bd3e;
  font-size: 20px;
}

.content_box_animation {
  background: #d9bd3e;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px;
  width: 85%;
  box-sizing: border-box;
}

.image_part {
  width: 40%;
  box-sizing: border-box;
  vertical-align: middle;
  border-style: none;
}

.text__part__1 {
  width: 55%;
  color: #ffffff !important;
}

.text__part__heading__home__section {
  color: white !important;
  font-weight: 600;
  font-size: 20px;
  margin: 5%;
}

.text__part__text__home__section {
  color: white;
  font-weight: 400;
  margin: 5%;
  font-size: 16px;
}

.aboutPic {
  width: 100% !important;
  object-fit: contain;
}

/* ######################Footer###################################### */
.Footer__main {
  padding: 40px 0px;
  background-color: #1e185f !important;
}

.footer__icons_all {
  display: flex;
}

.text__a {
  color: white;
  text-decoration: none;
}

.text__a:hover {
  color: white;
  text-decoration: none;
}

.footer__container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.footer__container__row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.footer__container__1 {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.footer__container__1__content {
  display: block;
  overflow: hidden;
  text-align: center !important;
}

.footer__container__image__part {
  display: flex;
  justify-content: center;
}

.footer__img {
  width: 150px;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
}

.footer__icons {
  margin-top: 30px;
}

.footer__social__icons {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.list__social__icons {
  display: inline-block;
}

.link__social__icons {
  border: 1px solid #444;
  color: #444;
  display: block;
  font-size: 16px;
  height: 40px;
  line-height: 38px;
  margin-right: 5px;
  text-align: center;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #eee !important;
  color: #eee !important;
  text-decoration: none !important;
  color: #eee !important;
}

.footer__container__2 {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
}

.footer__container__2 {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
}

.footer__container__2__content {
  display: block;
  overflow: hidden;
}

.footer__container__2__header {
  padding-bottom: 8px;
}

.footer__container__2__heading {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #d9bd3e !important;
  margin: 5px;
}

.footer__container__2__course__list {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.footer-container__2__course__list__number {
  width: 100%;
  border: 0;
  padding-bottom: 3px;
  margin-bottom: 5px;
}

.footer__container__2__course__list__link {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 10px;
  color: #e2e2e2 !important;
  text-decoration: none;
}

/* #################################pic-container-mobile########################### */
@media (min-width: 700px) {

  .mobile-video {
    display: none;
  }

  .content-feature-courses .coursesContainer .course-card {

    margin: 12px 6px !important;
  }
}

@media (max-width: 700px) {
  .height_w_of_image {
    height: auto !important;
  }

  .header-secion {
    top: -17px;
  }

  .header__main {
    height: 3.3rem !important;
  }

  .home__section__3 .col-md-12,
  .home__section__3 .p-3.row {
    margin: 0px !important;
    padding: 0px !important;
  }

  .my-video-dimensions {
    width: 640px;
    height: 187px;
  }

  .desktop-video {
    display: none !important;
  }

  .fae-mobile-tabs {
    display: block;
  }

  .icons__all {
    margin: initial;
  }

  .get-qualified {
    padding: 1rem 1rem !important;
    background: rgb(223 230 255 / 88%);
  }

  .bookingContainer {
    display: none;
  }

  .main__Image__Container {
    display: block;
    position: relative;
    width: 100%;
  }

  .form__container {
    display: block;
    width: 93%;
    background: #EBF5FC;
    border-radius: 10px;
    padding: 0px 13px;
    margin-top: 0px;
    margin-bottom: 30px;
    box-shadow: 1px 8px 7px rgb(0 0 0 / 10%);
    position: unset;
  }

  .bannerContent2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #fff;
    padding-top: 20px;
  }

  /* @media screen and (min-width: 881px) {
    .bannerContent2 {
      display: none !important;
    }
  } */


  .form__heading {
    width: 100%;
    height: auto;
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    line-height: 1px;
    text-align: center;
    color: #1e185f !important;
    margin-bottom: 3px;
    padding: 24px 0px 7px 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #1e185f00 !important;
  }

  .inputDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ebf5fc;
    /* padding: 4px; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 1px;
    padding-right: 1px;

  }



  .form__inputs {
    background-color: #D1E3F3;
  }

  .form__inputs {
    border-radius: 2%;
    border-radius: 2%;
    width: 100%;
    height: 35px;
    margin: 3px 3px;
    padding: 3px 0px 3px 5px;
  }

  .send__msg__div {
    color: #110B57;
    display: flex;
    justify-content: flex-start;
    float: right;
    margin-bottom: 4%;
    margin-right: 9%;
  }

  .msg__text {
    color: #1e185f;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 4%;
  }

  .send__msg__btn {
    margin-left: -11%;
  }

  /* #################################Sub-container########################################### */
  .sub__container {
    box-shadow: 7px 40px 21px -35px rgb(0 0 0 / 8%);
    display: flex;
    flex-direction: column;
    background-color: #fff !important;
    width: 100%;
    box-shadow: none;
    border-radius: 3px;
    background: #f2f9ff;
  }

  .sub__container2 {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    width: initial;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 20px;
    align-items: center;
  }

  .boxVContainer {
    width: 100%;
  }

  .boxV_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .boxV__top__h2 {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 19px;
    color: #1c1d4f !important;
    padding-bottom: 20px;
    text-align: center;
  }

  .information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 89%;
    height: auto;
    border-radius: 8px;
    margin: 0 auto;
    margin-bottom: 4% !important;
    margin-top: -4%;
    height: 64px;
  }

  .information__text {
    font-family: "Roboto" !important;
    padding: 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: #ffffff !important;
  }

  .sub__container__main {
    display: none;
  }

  .sub__container2 {
    display: block;
  }

  /* ##############################################cetagoryContainer########################################### */
  .CetagoryContainer__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    /* background: radial-gradient( 70.69% 70.69% at 50% 50%, #251e75 0%, #0b0638 100% ); */
    background-size: cover;
    background-blend-mode: multiply;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
  }

  .cetagoryContainer__row {
    width: 100% !important;

    padding-bottom: 100px;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .contentContainer {
    overflow: hidden;
    padding: 2px 0px !important;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /* .headContenter {
            margin-bottom: 30px;
          } */
  .headContenter__text {
    color: #110b57 !important;
    font-family: "Roboto" !important;
    text-align: center;
    font-weight: 500;
    font-size: 30px;
    line-height: 47px;
  }

  .headContenter__text2 {
    color: #1E185F !important;
    font-family: "Roboto" !important;
    width: 100%;
    font-style: normal;
    padding: 8px 31px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding-bottom: 8px;
    opacity: 0.6;
    padding: 4px 30px;
  }

  .home_Feature {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .custom__carosal__row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .all__row {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 10px !important;
    display: block !important;
  }

  .row__col {
    margin-bottom: 3rem !important;
    flex: 0 0 25%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    box-sizing: border-box;
    margin-top: -9%;
  }

  .row__images {
    width: -webkit-fill-available;
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    height: auto;
  }

  .content_box_feature {
    background: #1f105a;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 10px;
    height: 55px;
    align-items: center;
  }

  .feature_title {
    font-size: 14px;
    color: white;
  }

  .feature_price {
    background: #d9bd3e;
    padding: 18px 3px;
    border-radius: 50%;
    margin-top: -37px;
    color: white;
    font-size: 20px;
    text-align: center;
    height: 64px;
    width: 65px;
  }

  /* ##############################VideoContainer##################################################### */
  .main__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 22px 20px;
    margin-top: 0%;
  }

  .link__tag__a2 {
    text-decoration: none !important;
  }

  .sub__main__row {
    display: flex;
    flex-direction: column;
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .headerContainer__heading {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 47px;
    color: #110b57 !important;
    text-align: center;
    padding: 1px 5px 1px 5px;
  }

  .headerContainer__text {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #7d7d7d !important;
    text-align: center;
    padding: 5px;
  }

  .Feature_vid_Div {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    margin-bottom: 4%;
  }

  .courses__videos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .slider {
    width: 100%;
    position: relative;
    overflow: visible !important;

  }

  .slider_wrapper {
    /* transform: translateX(9199.89%); */
    box-sizing: border-box;
  }

  .slider__item {
    transform: translateX(10%);
    flex: 0 0 80%;
    max-width: 80%;
  }

  .coursesBox {
    width: 30%;
    height: 370px;
    background: #ffffff;
    box-shadow: 4px 4px 20px 5px rgb(0 0 0 / 7%);
    border-radius: 10px;
    margin-bottom: 30px;
    width: 226px !important;
    height: 93% !important;
  }

  .slider__video {
    cursor: pointer;
    height: 114px;
    font-family: "Roboto" !important;
  }

  .contentContainer {
    overflow: hidden;
    height: auto;

    font-family: "Roboto" !important;
  }

  .link__tag__a2 {
    text-decoration: none !important;
  }

  .contentContainer__text {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #0c083b !important;
    padding-bottom: 10px;
    text-decoration: none !important;
  }

  .discription {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 26px;
    color: #353535 !important;
    margin-left: 1.5%;
    margin-right: 1%;
  }

  /* ############################################About######################################################### */
  .home__section__3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -11%;
  }

  .home__section__3__row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .section3__row__h2 {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 47px;
    padding-top: 7%;
    justify-content: center;
    color: #110b57 !important;
  }

  .vedio__container__a {
    text-decoration: none !important;
  }

  .section__row__text {
    justify-content: center;
    width: 95%;
    padding: 1px 25px 23px;
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #444444 !important;
  }

  .main_div {
    height: 450px !important;
    display: flex;
    justify-content: center;

    box-sizing: border-box;
    margin-left: 13%;
  }

  .main_div__animation__1 {
    width: 100%;
    display: block;
    opacity: 1;
    height: auto;
  }

  .animation__box__1 {
    display: flex;
    box-sizing: border-box;
  }

  .content_box_animation {
    flex-direction: column;
  }

  .image_part {
    width: 100%;
  }

  .aboutPic {
    vertical-align: middle;
    border-style: none;
    vertical-align: middle;
    border-style: none;
  }

  .text__part__1 {
    width: 100%;
    color: #ffffff !important;
  }

  .text__part__heading__home__section {
    color: white !important;
    font-weight: 600;
    font-size: 20px;
  }

  .text__part__text__home__section {
    color: white;
    font-weight: 400;
    margin: 5px;
  }

  /* #############################Footer######################################## */
  .Footer__main {
    padding: 40px 0px;
    background-color: #1e185f !important;
  }

  .footer__container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .footer__container__row {
    display: block;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .footer__container__1 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 100%;
  }

  .footer__container__1__content {
    display: block;
    overflow: hidden;
    text-align: center !important;
    margin-bottom: 30px;
  }

  .footer__container__image__part {
    text-align: center !important;
  }

  .footer__img {
    width: 150px;
    vertical-align: middle;
    border-style: none;
  }

  .footer__icons {
    margin-top: 30px;
    box-sizing: border-box;
  }

  .footer__social__icons {
    margin-top: 0;
    margin-bottom: 1rem;
    list-style: outside none none;
    margin: 0;
    padding: 0;
  }

  .list__social__icons {
    display: inline-block;
  }

  .link__social__icons {
    border: 1px solid #444;
    color: #444;
    display: block;
    font-size: 16px;
    height: 40px;
    line-height: 38px;
    margin-right: 5px;
    text-align: center;
    width: 40px;
    border-radius: 50%;
  }

  .footer__container__2 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 100%;
    background-color: #1e185f;
    text-align: center;
  }

  .footer__container__2__content {
    display: block;
    overflow: hidden;
    margin-bottom: 30px;
  }

  .footer__container__2__header {
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .footer__container__2__heading {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #d9bd3e !important;
  }

  .footer__container__2__course__list {
    margin-top: 0;
    margin-bottom: 0rem;
    list-style: outside none none;
  }

  .footer__container__2__course__list__number {
    width: 100%;
    border: 0;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .footer__container__2__course__list__link {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 0px;
    color: #e2e2e2 !important;
  }

  /* #################################Header######################################## */

  .hover__div {
    display: none;
  }

  .short_tab_line_button {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
    text-decoration: none;
    display: block;
  }

  .shot_tab_span {
    color: #1f105a;
  }

  .short_tab_expand {
    background-color: rgb(30, 24, 95) !important;
    position: absolute;
    top: 80px;
    width: 90%;
    background: #1e185f;
    border-radius: 10px 0px 10px 10px;
    justify-content: left;
    height: fit-content !important;
    z-index: 11;
    margin-left: 5%;
  }

  .short_tab_ul {
    background-color: transparent !important;
    width: 80%;
    align-items: baseline;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .short_tab_li {
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: transparent !important;
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 20px;
    color: #ffffff !important;
    padding: 18px 10px;
  }

  .short__i__phone {
    display: flex;
    margin-left: -8%;
  }

  .short_a_li {
    text-decoration: none;
    color: white;
  }

  .header__main {
    position: absolute;
    background-color: white !important;
    padding: 0px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    top: 0;
  }

  .header__img {
    width: 35%;
    margin-left: -1rem;
  }

  .header__logo {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    height: auto;
  }

  .sub__header2 {
    display: none;
  }

  .sub__headers {
    display: none;
  }

  .short_tab {
    display: flex !important;
    justify-content: start;
    position: relative;
    width: 37%;
  }

  .courses__tab__container {
    display: flex;
    margin-top: 5px;
    right: -5px;
    position: relative;
  }

  .courses__tab {
    box-sizing: border-box;
  }

  .nav__link {
    text-decoration: none;
  }

  .short__heading {
    background: #d9bd3e;
    padding: 2px 10px;
    border-radius: 15px;
    color: white;
  }

  .portal__tab {
    margin-left: 10px;
  }

  .portal__nav__link {
    text-decoration: none;
  }

  .portal__short__heading {
    background: #1f105a;
    padding: 2px 10px;
    border-radius: 15px;
    color: white;
  }

  .footer__icons_all {
    display: flex;
    justify-content: center;
  }

  .mobile__navbar {
    display: block;
    position: absolute;
    top: 16px;
    left: 0px;
    width: 100%;
    background: #ffffff;
    z-index: 999;
  }

  .mobile_navbar_button__section button {
    background-color: initial;
    border: initial;
  }

  .mobile_navbar_container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px;
    margin: 0px;
  }

  .mobile_navbar_row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .mobile_navbar_button__section {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 9px 10px;
    justify-content: space-around;
    background: #1e185f;
  }

  .content-feature-courses .coursesContainer .course-card {
    height: 224px;
  }

  .course-card h3 {
    height: 52px;
  }

  .mobile_navbar_column {
    /* padding: 10px 15px; */
    border-bottom: 2px solid #d9bd3e;
    border-left: 2px solid #d9bd3e;
    border-right: 2px solid #d9bd3e;
  }

  .custom_mob_nav_sub_item {
    list-style: outside none none;
  }

  .custom_mob_nav_link {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    letter-spacing: initial;
    text-transform: capitalize;
    color: black;
    text-decoration: none !important;
    background-color: transparent;
    transition: all 0.3s ease 0s;
    cursor: pointer;
  }

  .dropdown_laser_menu_mob_ul {
    padding: 13px 17px 14px 24px;
    margin-bottom: 0rem;
  }

  .navBar2_buttons {
    padding: 2px 10px;
    border-radius: 15px;
    text-decoration: none !important;
    color: white;
  }

  .navBar2_buttons:hover {
    background: #d9bd3e;
    color: white;
  }
}

@media (max-width: 500px) {
  .fae-mobile-tabs {
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  .slider_wrapper {
    justify-content: center;
    transform: translateX(53%);
  }
}

@media screen and (max-width: 768px) {
  .popularCourses .slider__wrapper {
    justify-content: center;
    transform: translateX(57%);
  }

  .footer__container__1__content {
    margin-bottom: 30px;
  }
}


.get-qualified {
  padding: 2rem 4rem;
  background: rgb(223 230 255 / 88%);
}

.coursesDiscriptionCol h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 40px;
  letter-spacing: 0.025em;
  color: #1E185F !important;
  padding-bottom: 10px;
}

.footer__container__2__course__list__link a,
.footer__container__2__course__list__link .address {
  padding-left: 10px;
}

/* what next *************************************************888 */
.content-feature-courses .coursesContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 4;
  align-items: center;
  justify-content: center;
}

.tick-icon span {
  background-color: #d7d7d7;
  padding: 3px 5px 5px;
  border-radius: 3rem;
}

.content-feature-courses-update {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.content-feature-courses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 65px;
  padding-bottom: 65px;
}

.content-feature-courses .headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.content-feature-courses .headerContainer h2 {
  font-family: 'Roboto' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #110B57 !important;
  padding-bottom: 20px;
}

.content-feature-courses .coursesContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 4;
  align-items: center;
  justify-content: center;
}

.content-feature-courses .coursesContainer .course-card:nth-child(1) {
  background: linear-gradient(180deg, #A4C438 0%, #657728 100%);
}

.content-feature-courses .coursesContainer .course-card {
  display: flex;
  flex-direction: column;
  width: 23%;
  height: 213px;
  align-items: left;
  justify-content: center;
  margin: 12px 0px;
  padding: 3px 20px;
  border-radius: 8px;
}

.iconDiv {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: 30px;
}

.iconContainer {
  width: 39px !important;
  height: 38px !important;
}

.iconContainer {
  background: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.iconContainer i {
  font-size: 20px !important;
}

.iconContainer i {
  color: white;
  font-size: 30px;
}

.content-feature-courses .coursesContainer .course-card a {
  text-align: right;
}

.content-feature-courses .coursesContainer .course-card a {
  margin-left: auto;
}

.content-feature-courses .coursesContainer .course-card a {
  font-family: 'Roboto' !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  text-align: left;
  /* color: #D9BD3E !important; */
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}





.iconDiv {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: 30px;
}

.iconContainer {
  width: 39px !important;
  height: 38px !important;
}

.iconContainer {
  background: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.iconContainer i {
  font-size: 20px !important;
}

.display_mobile {
  display: none;
}

@media (max-width:700px) {
  .display_mobile {
    display: block;
  }

  .display_laptop {
    display: none;
  }

  .hover__div {
    border: initial;
  }

  .content_box_animation {
    padding: 7px 10px !important;
    width: 100% !important;
  }

  .flip__card {
    width: 100% !important;
    margin-left: 0% !important;
    margin-top: 0% !important;
  }

  .content-feature-courses .headerContainer h2 {
    margin-top: 0px;
    font-size: 34px;
    line-height: 34px;
    color: #110B57 !important;
    padding-bottom: 20px;
    padding-bottom: 0px !important;
  }

  .content-feature-courses .coursesContainer {
    display: block;
  }

  .content-feature-courses .coursesContainer .course-card {
    width: 100%;
  }

  .iconContainer {
    position: relative;
    top: 10px;
  }
}